.card_wrap_extra {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* height: 100vh; */
  height: auto;
  gap: 70px;
  /* margin-bottom: -15rem;
  margin-top: -15rem; */
  padding: 30px;
}

.card_extra {
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 15px;
  box-shadow: 0 0 25px #aaa;
  cursor: pointer;
  overflow: hidden;
}

.card__photo_extra {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.card__content_extra {
  position: absolute;
  bottom: 0;
  font-size: 15px;
  font-weight: 300;
  boder-radius: 15px;
  /* overflow: hidden; */
  padding: 10px;
  padding-bottom: 10px;
  color: #fff;
  transition: all 900ms;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  transform: translateY(85%);
}

.card__title_extra {
  font-size: 20px;
  font-weight: 500;
}

.card__description_extra {
  margin-top: 10px;
  font-size: 16px;
  width: 80%;
  line-height: 1.3;
}

.card_extra:hover > .card__content_extra {
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, .25);
  transform: translateY(0%);
}

@media screen and (max-width: 600px){
    .card_wrap_extra {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* width: 70vw;
        height: 60vh; */
        gap: 15px;
        /* margin-bottom: -5rem;
        margin-top: -5rem; */
        padding-right: 5.5rem;
  }
  .card_extra {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 15px;
    box-shadow: 0 0 25px #aaa;
    cursor: pointer;
    /* overflow: hidden; */
  }
  
  .card__photo_extra {
    width: 50%;
    height: 60%;
  }
  
  .card__content_extra {
    position: absolute;
    bottom: 0;
    font-size: 20px;
    font-weight: 400;
    border-radius: 15px;
    overflow: hidden;
    padding: 20px;
    padding-bottom: 20px;
    color: #fff;
    transition: all 900ms;
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    transform: translateY(85%);
  }
  
  .card__title_extra {
    font-size: 19px;
    font-weight: 400;
  }
  
  .card__description_extra {
    margin-top: 12px;
    font-size: 10px;
    width: 60%;
    line-height: 1.3;
  }
  .card_extra:hover > .card__content_extra {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, .25);
    transform: translateY(0%);
  }

}