 /* *{
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  } */
  
  /* h1{
    font-size: 2.5rem;
    font-family: 'Montserrat';
    font-weight: normal;
    color: #444;
    text-align: center;
    margin: 2rem 0;
  }  */
  
  .wrapper{
    width: 90%;
    margin: 0 auto;
    max-width: 130rem;
  }
  
  .cols{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .col{
    width: calc(25% - 2rem);
    margin: 1rem;
    cursor: pointer;
  }
  
  .container{
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
      -webkit-perspective: 1000px;
              perspective: 1000px;
  }
  
  .front,
  .back{
    background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
    /* border-radius: 10px; */
      background-position: center;
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      text-align: center;
      min-height: 280px;
      height: auto;
      border-radius: 10px;
      color: #fff;
      font-size: 1.5rem;
  }
  
  .back{
    background: #cedce7;
    background: -webkit-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: -o-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  }
  
  .front:after{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      opacity: .6;
      background-color: #000;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      border-radius: 10px;
  }
  .container:hover .front,
  .container:hover .back{
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
  
  .back{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  
  .inner{
      -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
              transform: translateY(-50%) translateZ(60px) scale(0.94);
      top: 50%;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 2rem;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      outline: 1px solid transparent;
      -webkit-perspective: inherit;
              perspective: inherit;
      z-index: 2;
  }
  
  .container .back{
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .container .front{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .container:hover .back{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .container:hover .front{
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .front .inner p{
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .front .inner p:after{
    content: '';
    width: 4rem;
    height: 2px;
    position: absolute;
    background: #C6D4DF;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -.75rem;
  }
  
  .front .inner span{
    color: rgba(255,255,255,0.7);
    font-family: 'Montserrat';
    font-weight: 300;
  }
  
  @media screen and (max-width: 64rem){
    .col{
      width: calc(33.333333% - 2rem);
    }
  }
  
  @media screen and (max-width: 48rem){
    .col{
      width: calc(50% - 2rem);
    }
  }
  
  @media screen and (max-width: 32rem){
    .col{
      width: 100%;
      margin: 0 0 2rem 0;
    }
  }



  /* next element*/

  /* body {
    background-color: #efefef;  
  }
  
  h3 {
    margin: 0;
  }
  
  h3+p {
    margin-top: 0;
  } */
  
  
  
  .my-wrapper {
    width: 90%;
    max-width: 940px;
    margin: 20px auto;
    background-color: #fff;
  }
  
  .home-hero {
    display: grid;
    grid-gap: 1px;
    grid-auto-rows: minmax(150px, auto);
  }
  
  .home-hero > * {
    padding: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #333;
    color: #fff;
  }
  
  .feature {
    /* background-image: url(''); */
    background-position: bottom left;
    color: #fff;
    object-fit: cover;
  }
  
  .photos {
    /* background-image: url('');  */
    color: #fff;
    object-fit: cover;
  }
  
  .news {
    /* background-image: url('');  */
    color: #fff;
  }
  
  .special {
    /* background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8)),url('https://unsplash.it/511/511/');  */
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .special h3,
  .amazing h3{
    margin-top: auto;
  }
  
  .amazing {
    /* background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.9)), url('https://unsplash.it/511/511/');  */
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .cta {
    background-color: rgb(123,14,34);
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  a.spam-button:link {
    border: 1px solid #ccc;
    border-radius: 3px;
    text-decoration: none;
    background-color: #fff;
    color: #333;
    display: block;
    padding: 5px;
    margin: auto auto 20px;
    width: 90%;
    text-align: center;
  }
  
  @media (min-width: 500px) {
    .home-hero {
      grid-template-columns: 1fr 1fr;
    }
    
    .feature {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
    
    .special {
      grid-column: 1;
      grid-row: 3 / 6;
    }
    .amazing {
      grid-column: 1;
      grid-row: 6;
    }
    .cta {
      grid-column: 2;
      grid-row: 5 / 7;
    }
  }
  
  @media (min-width: 800px) {
     .home-hero {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: minmax(150px, auto) minmax(150px, auto);
      grid-auto-rows: minmax(100px, auto);
    }
    .feature {
      grid-column: 1 ;
      grid-row: 1 / 6;
    }
    
    .special {
      grid-column: 2;
      grid-row: 1 / 4;
    }
    .amazing {
      grid-column: 2;
      grid-row: 4 / 6;
    }
    .cta {
      grid-column: 3;
      grid-row: 3 / 6;
    }
  }


  /*news section start*/

  
.body_news{
  /* background-color: #151320; */
  height: 100vh;
  display: grid;
  place-items: center center;
  font-family: 'Poppins',sans-serif;
}
.cards_news{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(400px,1fr));
  padding: 20px;
  grid-gap: 40px;
}
.card_news{
  background-color: #1c1b29;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0,0,0,0.18);
}
.container_news{
  position: relative;
  clip-path: polygon(0 0,100% 0, 100% 85%, 0 100%);
}
img_news{
  width: 100%;
  display: block;
  border-radius: 20px 20px 0 0;
}
.container_news:after{
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px 20px 0 0;
  opacity: 0.7;
}
.card1_news>.container_news:after{
  background-image: linear-gradient(
      135deg,
      #0100ec,
      #fb36f4
  );
}
.card2_news>.container_news:after{
  background-image: linear-gradient(
      135deg,
      #2bdce3,
      #42ffdb
  );
}
.card3_news>.container_news:after{
  background-image: linear-gradient(
      135deg,
      #ffab1d,
      #f8501c
  );
}
.details_news{
  padding: 20px 10px;
}
.details_news>h3{
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0 15px 0;
}
.details_news>p{
  color: #a0a0a0;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  /* .body_news {
    height: auto; 
    padding: 20px; 
  }
  .card_news {
    height: 100%;
  }
  .container_news {
    height: auto; 
  } */
}


