.container_accord {
    padding: 300px;
    margin-top: -25rem;
    margin-bottom: -16rem;
  }
  
  .faq-drawer_accord {
    margin-bottom: 30px;
  }
  
  .faq-drawer__content-wrapper_accord {
    font-size: 1.25em;
    line-height: 1.4em;
    max-height: 0px;
    overflow: hidden;
    transition: 0.25s ease-in-out;
  }
  
  .faq-drawer__title_accord {
    border-top: #000 1px solid;
    cursor: pointer;
    display: block;
    font-size: 2.0em;
    font-weight: 700;
    padding: 30px 0 0 0;
    position: relative;
    margin-bottom: 0;
    transition: all 0.25s ease-out;
  }
  
  .faq-drawer__title_accord::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: " ";
    display: inline-block;
    float: right;
    height: 10px;
    left: 2px;
    position: relative;
    right: 20px;
    top: 2px;
    transform: rotate(135deg);
    transition: 0.35s ease-in-out;
    vertical-align: top;
    width: 10px;
  }
  .faq-drawer__content_accord{
    font-size: 1.2em; 
  }
  /* OPTIONAL HOVER STATE */
  .faq-drawer__title_accord:hover { 
    color: #4E4B52;
  }
  
  .faq-drawer__trigger_accord:checked
    + .faq-drawer__title_accord
    + .faq-drawer__content-wrapper_accord {
    max-height: 350px;
  }
  
  .faq-drawer__trigger_accord:checked + .faq-drawer__title_accord::after {
    transform: rotate(-45deg);
    transition: 0.25s ease-in-out;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  @media only screen and (max-width: 600px) {
    .container_accord {
      padding: 50px;
      padding-left: 20px;
      margin-top: -10rem;
      margin-bottom: -4rem;
    }
    .faq-drawer__title_accord {
        font-size: 1.5em;
      }
  }
  