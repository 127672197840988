

.wrapper_gallery{
	margin:40px;
	width:90%;
	display:flex;
	justify-content:space-around;
  }
  
  .card_gallery{
	position:relative;
	width:280px;
	height:360px;
	padding:20px;
	background:#fff;
	display:flex;
	align-items:flex-end;
	transition: 0.5s all;
	border-radius:30px;
  }
  
  .card_gallery:hover{
	transform:translateY(20px);
  }
  
  .card_gallery:before{
	content:'';
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	display:block;
	background:linear-gradient(to bottom,rgba(245, 144, 2,0.5),rgba(26, 245, 2,0.5));
	opacity:0;
	z-index:2;
	transition:0.5s all;
	border-radius:30px;
  }
  
  .card_gallery:hover:before{
	opacity:1;
  }
  
  .card_gallery img{
	width:100%;
	height:100%;
	top:0;
	left:0;
	object-fit:cover;
	position:absolute;
	border-radius:30px;
  }
  
  .card_gallery .info_gallery{
	position:relative;
	z-index:3;
	color:#fff;
	opacity:0;
	transition:0.5s all;
	transform:translateY(30px);
	font-family:verdana;
  }
  
  .card_gallery:hover .info_gallery{
	opacity:1;
	transform:translateY(0px);
  }
  
  .card_gallery .info_gallery h2{
	margin:0;
  }
  
  .card_gallery .info_gallery p{
	letter-spacing:1px;
	font-size:15px;
	margin-top:8px;
	margin-bottom:20px;
  }
  
  .card_gallery .info_gallery a{
	text-decoration:none;
	padding:0.5rem 1rem;
	background:#fff;
	color:#000;
	font-size:14px;
	font-weight:bold;
	transition: 0.4s ease-in-out;
  }
  
  .card_gallery .info_gallery a:hover{
	box-shadow: 0 7px 10px rgba(0,0,0,0.5);
  }

  /* Media Queries for Responsive Design */
@media (max-width: 768px) {
	.wrapper_gallery {
		margin: 20px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 40px; /* Spacing to avoid overlap with footer */
	}
	
	.card_gallery {
		width: 90%;
		height: auto;
		padding: 15px;
		margin-bottom: 30px; /* Increased bottom margin for spacing */
	}
	
	.card_gallery img {
		width: 100%;
		height: auto;
	}
	
	.card_gallery .info_gallery {
		transform: translateY(20px);
	}
	
	.card_gallery:hover .info_gallery {
		transform: translateY(0px);
	}
}

@media (max-width: 480px) {
	.wrapper_gallery {
		margin: 10px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 40px; /* Spacing to avoid overlap with footer */
		height: auto;
	}
	
	.card_gallery {
		width: 100%;
		height: 100%;
		padding: 120px;
		margin-bottom: 30px; 
	}
	
	.card_gallery img {
		width: 100%;
		height: 100%;
	}
	
	.card_gallery .info_gallery {
		transform: translateY(20px);
	}
	
	.card_gallery:hover .info_gallery {
		transform: translateY(0px);
	}
}