/*first component*/

/* Global settings */

/* Mixins replaced with media queries */
@media (min-width: 600px) {
  .news-card {
    height: 20rem;
  }
}

@media (min-width: 900px) {
  .news-card {
    height: 12rem;
  }
}

/* Content wrapper */
.content-wrapper_transport {
  margin: 0 auto;
  max-width: 1200px;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0.5rem;
}

/* News card styles */
.news-card {
  border: 0px solid aqua;
  margin: 0.5rem;
  position: relative;
  height: auto;
  overflow: hidden;
  border-radius: 0.5rem;
  flex: 1;
  margin-right:3rem;
  margin-left: 3rem;
  min-width: 320px;
  width: 320px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.news-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 80%);
  z-index: 0;
}

.news-card__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.news-card__image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: transform 3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: -1;
}

.news-card__text-wrapper {
  position: absolute;
  bottom: 0rem;
  padding: 1rem;
  color: white;
  transition: background-color 1.5s ease;
}

.news-card__title {
  transition: color 1s ease;
  margin-bottom: .5rem;
  font-size: 2.5rem;
}

.news-card__post-date {
  font-size: .9rem;
  margin-bottom: .5rem;
  color: #CCC;
}

.news-card__details-wrapper {
  max-height: 0;
  opacity: 0;
  transition: max-height 1.5s ease, opacity 1s ease;
}

.news-card:hover .news-card__details-wrapper {
  max-height: 12rem; /* Adjusted to mobile height for demonstration */
  opacity: 1;
}

.news-card:hover .news-card__text-wrapper {
  background-color: rgba(0, 0, 0, 0.6);
}

.news-card:hover .news-card__title {
  color: yellow;
}

.news-card:hover .news-card__image {
  transform: scale(1.2);
  z-index: -1;
}

.news-card__excerpt {
  font-weight: 300;
}

.news-card__read-more {
  background: black;
  color: #bbb;
  display: block;
  padding: 0.4rem 0.6rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  border: 1px solid #444;
  font-size: 0.8rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none;
  width: 7rem;
  margin-left: auto;
  position: relative;
  z-index: 5;
}

.news-card__read-more i {
  position: relative;
  left: 0.2rem;
  color: #888;
  transition: left 0.5s ease, color 0.6s ease;
}

.news-card__read-more:hover i {
  left: 0.5rem;
  color: yellow;
}


/*another container*/

.info-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /* padding: 100px; */
  border: 2px solid blue;
  /* margin-left: 5rem; */
}

.side_a{
  width: 50%;
  border: 2px solid blue;
}

.side_b{
  width: 50%;
  border: 2px solid blue;
}


/* following card*/
/* van and some additional information*/

.body_info_wrapper {
  font-size: 16px;
  line-height: 1.5;
}
img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ccc;
}
.cards_transport_info {
  margin: 40px auto;
  max-width: 1140px;
  display: flex;
}
.card_transport_info, .img-card_transport_info {
  width: calc(50% - 2rem);
  margin: 0 1rem;
}
.card_transport_info {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 2rem;
  border-radius: 20px;
}
.card_transport_info :first-child {
  margin-top: 0;
}
.card_transport_info .card-footer_transport_info {
  margin-top: auto;
}
.img-card_transport_info img {
  border-radius: px;
}
.cta_transport_info {
  padding: 0.5rem 1rem;
  text-decoration: none;
  background: #06c;
  color: #fff;
  border-radius: 3px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cards_transport_info {
    flex-direction: column;
  }

  .card_transport_info, .img-card_transport_info {
    width: 100%;
    margin: 1rem 0; /* Add spacing between stacked cards */
  }
}



/* First section and components */

/* @import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap"); */
/* * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
} */
.top {
	background: url("../../../../prod_images/TRANSPORT/van_loading.jpg")
		rgba(0, 0, 0, 0.5) no-repeat;
	background-size: 100% 100%;
	background-attachment: fixed;
	background-blend-mode: darken;
	width: 100%;
	height: max-content;
	/* height: auto; */
	/* 	position: relative; */
	height: max-content;
	padding: 2em 0;
}
.top h1 {
	color: white;
	/* 	position: absolute; */
	/* 	to/p: 50%; */
	/* 	left: 50%; */
	/* 	transform: translate(-50%, -50%); */
	z-index: 200;
	font-family: "Dancing Script", cursive;
	font-size: 5em;
	width: 100%;
	text-align: center;
}
address {
	padding: 2em;
	background:rgba(152,219,5,1.000);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.location {
	display: flex;
	align-items: center;
	justify-content: center;
}
.location p {
	font-size: 1em;
	color: #fff;
}
.location svg {
	width: 15px;
	margin-right: 0.4em;
	fill: #fff;
}
.contact {
	margin-top: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
}
.contact svg {
	width: 15px;
	margin-right: 0.4em;
	fill: #fff;
}
.contact a {
	font-size: 1em;
	color: #fff;
	text-decoration: none;
}
.hours {
	border: 1px solid lightgray;
	border-collapse: collapse;
	width: 100%;
}
.hours thead tr th {
	padding: 0.2em;
	font-size: 2.4em;
	font-family: "Dancing Script", cursive;
	color: #009ecd;
}
.hours tbody tr td {
	padding: 10px 30px;
	text-align: center;
}
hr {
	margin: 1em 0;
}
.grid {
	padding: 1em;
	margin-top: 2em;
	display: grid;
	grid-template-columns: 1fr;
	gap: 2em;
}
@media (min-width: 768px) {
	.grid {
		grid-template-columns: 1fr 1fr;
	}
}
.grid div {
	padding: 1em;
	/* 	border: 1px solid lightgray; */
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	height: max-content;
}
.grid div h2 {
	text-align: center;
	margin: 0.7em 0;
	font-size: 2em;
	font-family: "Dancing Script", cursive;
}

.grid div .prices {
	font-size: 1.3em;
	margin: 0.5em 0;
	font-weight: semibold;
}
.grid div dl {
	padding: 0.7em;
}
.grid div dl dt {
	font-size: 1.2em;
	font-weight: semibold;
	margin-bottom: 0.2em;
}
.grid div dl dd {
	padding: 1em;
	margin-bottom: 1em;
}
.description > p{
  font-size: 1.5rem;
}
p > span{
  font-size: 2.5rem;
  font-weight: bold;
}
dt{
  font-weight: bold;
  font-size: 2rem;
}

/* images of the van */

.body_van {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: -20rem;
  margin-bottom: -20rem;
}
@media(max-width:768px){
  .body_van{
    margin-top: -1rem;
    margin-bottom: 2.5rem;
  }
}

.image-card_van {
  position: relative;
  width: 370px;
  height: 350px;
  margin: 20px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  margin-left: 5rem;
}

.image-card_van:hover {
  transform: scale(1.05);
}

.image-card_van img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.image-card_van:hover img {
  opacity: 0.7;
}

.image-card_van .overlay_van {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

.image-card_van:hover .overlay_van {
  opacity: 1;
}

.overlay-content_van {
  text-align: center;
  color: #fff;
}

.overlay-title_van {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.overlay-description_van {
  font-size: 1em;
}


