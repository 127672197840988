.carousel {
  /* margin: auto; */
  /* width: 60%; */
  height: 100vh;
  /* background: red; */
  /* z-index: 100; */
}

div#pullUp{
  margin-top: 10rem;
}
.image-container {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(600px,1fr));
  gap: 30px;
  border:none;
  border: 0;
}

.image {
  /* width: 600px; */
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border:none;
  border: 0;
}

.slider-container {
  margin: auto;
  width: 90%;
  /* background: rgb(71, 56, 56); */
  z-index: 100;
  position: relative;
  top: 30%;
  left: 45%;
  transform: translate(-50%);
}

.slider-container h2 {
  text-align: center;
  font-size: 50px;
}
.minute{
  font-size: 40px;
}

.slider-container h1 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 15rem;
}


@media screen and (max-width: 1024px) {
  .image {
    width: 300px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .slider-container h2 {
    text-align: center;
    /* font-size: 40px; */
    font-size: 60px;
  }
  .slider-container h1 {
    /* font-size: 30px; */
    font-size: 20px;
    text-align: center;
    margin-bottom: 5rem;
    text-wrap: nowrap;
  }
  .animated-heading {
    /* background: black; */
    letter-spacing: 1px;
    width: 70%;
    margin:auto;
  }
  .minute{
    font-size: 13px;
  }
  div#pullUp{
    margin-top: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .carousel {
    margin: auto;
    width: 90%;
    height: 100vh;
    /* background: red; */
    /* z-index: 100; */
  }
  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .slider-container h2 {
    text-align: center;
    /* background: white; */
    font-size: 15px;
    /* animation: slideInFromLeft 1s ease-out; */
  }
  .slider-container h1 {
    font-size: 12px;
    /* background: white; */
    text-align: center;
    margin-bottom: 5rem;
    text-wrap: wrap;
    /* font-style: italic; */
    color: orange 0%;
      
    /* animation: slideInFromRight 1s ease-out; */
  }
  .slider-container {
    margin: auto;
    width: 90%;
    /* background: rgb(71, 56, 56); */
    z-index: 100;
    position: relative;
    top: 50%;
    left: 45%;
    transform: translate(-50%);
  }
  .animated-heading {
    letter-spacing: 1px;
    width: 70%;
    /* margin:auto; */
    text-wrap: wrap;
  }
  .minute{
    font-size: 13px;
  }
  .moveTop{
    margin-top:-30rem;  
  }
  .wordings{
    margin-top: -15rem !important;
  }
  div#pullUp{
    margin-top: 5rem;
  }
}

.animated-heading {
  /* background: black; */
  letter-spacing: 1px;
  width: 70%;
  margin:auto;
}
h1.spanish{
  
}

.text-uppercase {
  text-transform: uppercase;
}

